import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import "./FeaturedWork.css";

export default class FeaturedWork extends React.Component {
  render() {
    const StyledContainer = styled.div`
      background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url(${props => require(`../../Assets/images/` + this.props.img.mobile  )});
      background-position: center center;
      background-size: cover;
      @media (min-width: 768px) {
        background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
          url(${props =>
            require(`../../Assets/images/` + this.props.img.desktop)});
        background-position: center center;
        background-size: cover;
      }
    `;
    return (
      <StyledContainer className="featured container">
        <div className="content">
          <h4 className="h4-styles">{this.props.title}</h4>
          <h3 className="h3-styles" title={this.props.description}>{this.props.description}</h3>
          <Link to={this.props.link} className="h4-styles">
            View Case
          </Link>
        </div>
      </StyledContainer>
    );
  }
}
