import React from "react";
import { Container } from "reactstrap";

// Styles
import "./Footer.css";

//Logo
// import Logo from "../../Assets/images/logos/logo-white.png";
import Logo from "../../Assets/images/logos/Zerotrillion - Logo - White.svg";

export default class Footer extends React.Component {
  render() {

    const getYear = new Date().getFullYear();

    return (
      <footer className="footer row  animated fadeIn">
        <Container>
          <div className="row">
            <div className="col-12 col-lg-6 content-left">
              <img src={Logo} alt="Zerotriwllion Logo" />


              <div className="email">
                <h4>New Business Inquiries</h4>
                <a
                  href="mailto:newbusiness@zerotrillion.com"
                  className="primary-copy"
                >
                  newbusiness@zerotrillion.com
                </a>
              </div>
            </div>
            <div className="col-12 col-lg-6 content-right">
              <div className="social-links">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/company/zerotrillion/"
                >
                  <i className="fa fa-linkedin" />
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/zerotrillion/"
                >
                  <i className="fa fa-instagram" />
                </a>
              </div>
              <div className="legal">
                <p>© {getYear} Zerotrillion.</p>
              </div>
            </div>
          </div>
        </Container>
      </footer>
    );
  }
}
