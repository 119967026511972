import React from "react";

// Data
import data from "../Data/careers.json";

// Components
import JobPosting from "../Components/JobPosting/JobPosting.js";
import VerText from "../Components/VerText/VerText";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";

class Careers extends React.Component {
  state = {};

  componentDidMount() {
    const mainElement = document.getElementById("root");
    mainElement.classList.add("careers");
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  componentWillUnmount() {
    const mainElement = document.getElementById("root");
    mainElement.classList.remove("careers");
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  render() {
    const postings = data.postings.map(posting => {
      return (
        <JobPosting
          title={posting.title}
          location={posting.location}
          description={posting.position_desc}
        />
      );
    });

    return (
      <React.Fragment>
        <Header />
        <section className="intro animated fadeIn custom-container line">
          <VerText classNames="left" text="Careers" />
          <div className="content">
            <h1 className="h1-styles animated-t2 fadeIn delay-1">
              We’re looking for people with high EQ and uncommon talent who want
              to deliver moonshot creativity. Find your calling with
              Zerotrillion.
            </h1>
          </div>
        </section>
        <div className="custom-container animated fadeIn">{postings}</div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Careers;
