import React from "react";
import { Container, Row } from "reactstrap";

//Components
import VerText from "../Components/VerText/VerText";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

// Images
import Toronto from "../Assets/images/contact/toronto-hero.png";
import Amsterdam from "../Assets/images/contact/amsterdam-hero.png";
import Newyork from "../Assets/images/contact/ny-hero.jpg";

class Contact extends React.Component {
  state = {};

  componentDidMount() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  componentWillUnmount() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  render() {
    const ContactCard = props => {
      return (
        <div className="col-12 col-lg-4 mb-5 pb-md-3 pr-0 text-center text-lg-left contact-card">
          <LazyLoadImage effect="opacity" src={props.img} alt={props.alt} />
          <div className="content">
            <h3 className="h3-styles">{props.title}</h3>
            <p className="p-styles">{props.address1}</p>
            <p className="p-styles">{props.address2}</p>
            <a href={"tel:" + props.contact} className="p-styles">
              {props.contact}
            </a>
            <a href={"mailto:" + props.email} className="p-styles">
              {props.email}
            </a>
          </div>
        </div>
      );
    };

    return (
      <React.Fragment>
        <Header />
        <section className="contact-section custom-container animated fadeIn">
          <VerText classNames="left" text="Contact" />
          <Container>
            <Row>
              <ContactCard
                title="New York"
                img={Newyork}
                alt="New York"
                address1="675 Hudson St #4s"
                address2="New York, NY 10014"
                // contact="+1 (416) 816 8609"
                email="office.nyc@zerotrillion.com"
              />
              <ContactCard
                title="Amsterdam"
                img={Amsterdam}
                alt="Amsterdam"
                address1="Pieter Aertszstraat 94, 1074 VS"
                address2="Amsterdam, the Netherlands"
                // contact="+31 (0) 6 55 58 18 68"
                email="office@zerotrillion.com"
              />
              <ContactCard
                title="Toronto"
                img={Toronto}
                alt="Toronto"
                address1="302A-109 Atlantic Ave"
                address2="Toronto, ON M6K 1G1"
                // contact="+1 (416) 816 8609"
                email="office.to@zerotrillion.com"
              />
            </Row>
          </Container>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Contact;