import React from "react";

import NYF_Logo from '../../Assets/images/logos/NYF_Logo.png';
import './stickybar.scss';

export default class StickyBar extends React.Component {
  render() {
    return (
        <div className="stickybar row">
            <img src={NYF_Logo} alt="New York Festivals Logo"/>
            <h3>"Zerotrillion Named Best New Agency of the Year"</h3>
        </div>
    );
  }
}
