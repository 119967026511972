import React, { Component } from "react";
import { Row, Col } from "reactstrap";

// css
import "./LoadMoreBtn.css";

const LoadMoreBtn = props => {
  return (
    <Row
      className={
        "text-center loadmore-container mt-5 mb-5 " +
        (!props.hide ? "hide" : null)
      }
    >
      <Col>
        <button className="h4-styles" onClick={props.onClick}>
          Load More
        </button>
      </Col>
    </Row>
  );
};

export default LoadMoreBtn;
