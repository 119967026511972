import React from "react";
import { Collapse, Navbar, NavbarToggler, Nav, NavItem } from "reactstrap";
import { Link } from "react-router-dom";

// Styles
import "./Header.css";

// Logo
import Logo from "../../Assets/images/logos/logo.jpg";

export default class Header extends React.Component {
  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true
    };
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  render() {
    return (
      <Navbar
        className="container pb-4 pb-lg-5  animated fadeIn"
        color="faded"
        light
        expand="lg"
      >
        <NavbarToggler onClick={this.toggleNavbar} />
        <Link className="navbar-brand" to="/">
          <img src={Logo} alt="Zerotrillion Logo" />
        </Link>
        <Collapse isOpen={!this.state.collapsed} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <Link className="nav-link" to="/work">
                Work
              </Link>
            </NavItem>
            <NavItem>
              <Link className="nav-link" to="/about">
                About
              </Link>
            </NavItem>
            <NavItem>
              <Link className="nav-link" to="/services">
                Services
              </Link>
            </NavItem>
            {/* <NavItem>
              <Link className="nav-link" to="/thoughts">
                Thoughts
              </Link>
            </NavItem> */}
            <NavItem>
              <Link className="nav-link" to="/careers">
                Careers
              </Link>
            </NavItem>
            <NavItem>
              <Link className="nav-link" to="/contact">
                Contact
              </Link>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}
