import React from "react";
import Slider from "react-slick";
import Fade from "../../Assets/images/fade.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

// Slick Sliderreact-slick
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import "./Carousel.css";

export default class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2
    });
  }

  render() {
    const sliderItems = this.props.services.map(service => {
      return (
        <h3 className="h3-styles" data-name={service.title}>
          {service.title}
        </h3>
      );
    });

    var settings = {
      dots: false,
      arrows: false,
      infinite: true,
      slidesToScroll: 1,
      focusOnSelect: true,
      centerMode: true,
      responsive: [
        {
          breakpoint: 99999,
          settings: {
            slidesToShow: 5
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1
          }
        }
      ],
      onInit: function() {
        window.dispatchEvent(new Event("resize"));
      }
    };

    var customSettings = {
      dots: false,
      arrows: false,
      infinite: true,
      slidesToScroll: 1,
      focusOnSelect: true,
      centerMode: true,
      slidesToShow: 5,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1
          }
        }
      ],
      onInit: function() {
        window.dispatchEvent(new Event("resize"));
      }
    };

    const activeServiceContent = this.props.services.map(item => {
      return (
        <React.Fragment>
          <div className="row">
            <div className="col-12 col-lg-7 order-lg-12 image">
              <LazyLoadImage
                effect="opacity"
                src={require("../../Assets/images/" + item.img)}
                alt={item.title}
              />
            </div>
            <div className="col-12 col-lg-5 order-lg-1 copy">
              <div>
                <h3 className="h3-styles">{item.title}</h3>
                <p className="p-styles">{item.description}</p>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    });

    if (this.props.showSlideDetails) {
      return (
        <React.Fragment>
          <div className="services-details">
            <div className="slider-section container">
              <img src={Fade} className="fadeImg" />

              <Slider
                className="services-slider"
                asNavFor={this.state.nav1}
                ref={slider => (this.slider2 = slider)}
                {...customSettings}
              >
                {sliderItems}
              </Slider>
            </div>
            <div className="container">
              <Slider
                className="service-details"
                fade={true}
                asNavFor={this.state.nav2}
                adaptiveHeight={true}
                ref={slider => (this.slider1 = slider)}
              >
                {activeServiceContent}
              </Slider>
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className="slider-section container">
            <img src={Fade} className="fadeImg" />

            <Slider className="services-slider" {...settings}>
              {sliderItems}
            </Slider>
          </div>
        </React.Fragment>
      );
    }
  }
}
