import React from "react";
import { Col } from "reactstrap";
import { Link } from "react-router-dom";
import styled from 'styled-components';

// Styles
import "./Card.css";

class card extends React.Component {
  render() {

    const Div = styled.div`

    background-image: url(${require('../../Assets/images/'+this.props.img)});
    height: 150px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    @media only screen and (min-width : 500px) {
      height: 200px;
    }

    @media only screen and (min-width : 768px) {
      height: 150px;
    }

    @media only screen and (min-width : 1200px) {
      height: 175px;
    }

    @media only screen and (min-width : 1400px) {
      height: 200px;
    }

    @media only screen and (min-width : 1600px) {
      height: 225px;
    }

    @media only screen and (min-width : 1750px) {
      height: 250px;
    }

    `

    return (

      <Col
        onClick={this.props.onClick}
        className={"post animated fadeIn " + this.props.customClassName}
      >
        <Link to={this.props.link}>
          <Div className="post-image-container">
            {/* {this.props.img ? (
              <LazyLoadImage
                effect="opacity"
                src={require("../../Assets/images/" + this.props.img)}
                alt={this.props.img}
              />
            ) : null} */}
          </Div>
          <div className="post-content-container mt-2 mx-2">
            <h4 className="post-type h4-styles">
              {this.props.type} <i className="mr-2 ml-1" />
              <span className="post-date">{this.props.date}</span>
            </h4>
            <h3 className="post-title h3-styles mt-2">
              {this.props.status.toLowerCase() === "draft"
                ? "Coming soon"
                : this.props.title}
            </h3>
          </div>
        </Link>
      </Col>
    );
  }
}

export default card;
