import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

//css
import "./JobPosting.css";

class JobPosting extends Component {
  state = {};

  render() {
    return (
      <Container>
        <Row className="posting py-5">
          <Col xs="12" md={{ size: 3 }} className="pl-0 ">
            {this.props.title ? (
              <p className="h3-styles">{this.props.title}</p>
            ) : null}
            {this.props.location ? (
              <p className="h4-styles mt-2">{this.props.location}</p>
            ) : null}
          </Col>
          <Col
            xs="12"
            md={{ size: 6, offset: 3 }}
            lg={{ size: 8, offset: 1 }}
            className="pt-4 pt-md-0 pl-0"
          >
            <p className="role-desc h4-styles d-none d-md-block">
              ROLE DESCRIPTION
            </p>
            {this.props.description ? (
              <p className="p-styles">{this.props.description}</p>
            ) : null}
            <a href="mailto:careers@zerotrillion.com" className="h4-styles">
              Apply Now
            </a>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default JobPosting;
