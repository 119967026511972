import React from "react";
import { Row, Col } from "reactstrap";

// Components
import Card from "../Components/Card/Card";
import VerText from "../Components/VerText/VerText";
import FeaturedWork from "../Components/FeaturedWork/FeaturedWork";
import LoadMoreBtn from "../Components/LoadMoreBtn/LoadMoreBtn";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";

// Data
import workData from "../Data/Work.json";

class Work extends React.Component {
  state = {
    allPosts: workData.work,
    postsLeftToShow: [],
    postsToShow: [],
    featuredPost: [],
    numberOfPostsToShow: 50
  };

  componentDidMount() {
    const mainElement = document.getElementById("root");
    mainElement.classList.add("work");

    // Scroll to Top on page load
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

  //   const allPostsExceptFeatured = this.state.allPosts.filter(work => {
  //     if (!work.featured) {
  //       return work;
  //     }
  //   });

  //   if (allPostsExceptFeatured.length > this.state.numberOfPostsToShow) {
  //     let allPosts = allPostsExceptFeatured.slice(0);
  //     let itemsToShow = allPosts.slice(0, this.state.numberOfPostsToShow);
  //     let itemsLeftToShow = allPosts.splice(
  //       this.state.numberOfPostsToShow,
  //       allPosts.length
  //     );

  //     this.setState({
  //       postsToShow: itemsToShow,
  //       postsLeftToShow: itemsLeftToShow
  //     });
  //   } else {
  //     this.setState({
  //       postsToShow: this.state.allPosts
  //     });
  //   }
  }

  // loadMoreClicked = () => {
  //   let allPosts = this.state.postsLeftToShow.slice(0);

  //   let moreItemsToShow = allPosts.slice(0, this.state.numberOfPostsToShow);
  //   let itemsLeftToShow = allPosts.splice(
  //     this.state.numberOfPostsToShow,
  //     allPosts.length
  //   );

  //   this.setState(prevState => ({
  //     postsToShow: [...prevState.postsToShow, ...moreItemsToShow],
  //     postsLeftToShow: itemsLeftToShow
  //   }));
  // };

  render() {
    const featuredWork = [];
    const otherWorkArticles = [];

    this.state.allPosts.filter(work => {
      if (work.featured) {
      
        featuredWork.push(
          <FeaturedWork
            title={work.card_title}
            description={work.short_desc}
            img={work.thumbnail_img}
            link={"/work/" + work.link}
          />
        );
      } else{
        otherWorkArticles.push(
          <Card
            customClassName="col-12 col-md-6 mt-4"
            link={"work/" + work.link}
            type={work.card_title}
            date={""}
            status={work.status}
            title={work.card_desc}
            img={work.thumbnail_img.desktop}
          />
        );
      }
    });

   

    // this.state.postsToShow.filter(work => { 
    //   otherWorkArticles.push(
    //     <Card
    //       customClassName="col-12 col-md-6 mt-4"
    //       link={"work/" + work.link}
    //       type={work.card_title}
    //       date={""}
    //       status={work.status}
    //       title={work.card_desc}
    //       img={work.thumbnail_img.desktop}
    //     />
    //   );
    // });

    return (
      <React.Fragment>
        <Header />
        <section className="work-page animated fadeIn">
          <div className="custom-container">
            <VerText classNames="left" text="Work" />
            {featuredWork}
          </div>
          <div className="container other-workArticles">
            <div className="row mb-5">{otherWorkArticles}</div>
          </div>
          {/* <LoadMoreBtn
            hide={this.state.postsLeftToShow.length > 0 ? true : false}
            onClick={this.loadMoreClicked}
          /> */}
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Work;
