import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ReactGA from 'react-ga';

// styles
import "bootstrap/dist/css/bootstrap.min.css";
import "./Assets/styles/App.css";

//views
import Home from "./Views/Home";
import Work from "./Views/Work";
import SingleWork from "./Views/SingleWork";
import About from "./Views/About";
import Services from "./Views/Services";
import Thoughts from "./Views/Thoughts";
import SingleThought from "./Views/SingleThought";
import Careers from "./Views/Careers";
import Contact from "./Views/Contact";
import StickyBar from "./Components/StickerBar/StickyBar";

class App extends Component {
  state = {};
  render() {
    ReactGA.initialize('UA-130209978-1');
    return (
      <Router>
        <React.Fragment>
          <StickyBar/>
          <Route exact path="/" component={() => <Home />} />
          <Route exact path="/work" component={() => <Work />} />
          <Route exact path="/work/:id" component={() => <SingleWork />} />
          <Route exact path="/about" component={() => <About />} />
          <Route exact path="/services" component={() => <Services />} />
          <Route exact path="/thoughts" component={() => <Thoughts />} />
          <Route
            exact
            path="/thoughts/:id"
            component={() => <SingleThought />}
          />
          <Route exact path="/careers" component={() => <Careers />} />
          <Route exact path="/contact" component={() => <Contact />} />
        </React.Fragment>
      </Router>
    );
  }
}

ReactDOM.render(<App />, document.getElementById("root"));
