import React from "react";
import { Container, Row, Col } from "reactstrap";

// Getting data
import data from "../Data/Thoughts";

//Components
import Card from "../Components/Card/Card";
import VerText from "../Components/VerText/VerText";
import LoadMoreBtn from "../Components/LoadMoreBtn/LoadMoreBtn";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";

class Thoughts extends React.Component {
  state = {
    allPosts: data.thoughts.articles,
    postsLeftToShow: [],
    postsToShow: [],
    numberOfPostsToShow: 9
  };

  componentDidMount() {
    const mainElement = document.getElementById("root");
    mainElement.classList.add("thoughts");

    // Scroll to Top on page load
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    if (this.state.allPosts.length > this.state.numberOfPostsToShow) {
      let allPosts = this.state.allPosts.slice(0);
      let itemsToShow = allPosts.slice(0, this.state.numberOfPostsToShow);
      let itemsLeftToShow = allPosts.splice(
        this.state.numberOfPostsToShow,
        allPosts.length
      );

      this.setState({
        postsToShow: itemsToShow,
        postsLeftToShow: itemsLeftToShow
      });
    } else {
      this.setState({
        postsToShow: this.state.allPosts
      });
    }
  }

  loadMoreClicked = () => {
    let allPosts = this.state.postsLeftToShow.slice(0);

    let moreItemsToShow = allPosts.slice(0, this.state.numberOfPostsToShow);
    let itemsLeftToShow = allPosts.splice(
      this.state.numberOfPostsToShow,
      allPosts.length
    );

    this.setState(prevState => ({
      postsToShow: [...prevState.postsToShow, ...moreItemsToShow],
      postsLeftToShow: itemsLeftToShow
    }));
  };

  render() {
    const postsComponents = this.state.postsToShow.map(post => {
      return (
        <Card
          customClassName="col-12 col-sm-6 col-md-4 mt-5 pt-md-0"
          link={"thoughts/" + post.link}
          type={post.type}
          date={post.date}
          title={post.title}
          status={post.status}
          img={post.image}
        />
      );
    });

    const LoadMoreButtonComponent = () => {
      return (
        <Row className="text-center loadmore-container mb-3 pb-3 mb-sm-5 pb-sm-5">
          <Col>
            <button onClick={this.loadMoreClicked}>Load More</button>
          </Col>
        </Row>
      );
    };

    return (
      <React.Fragment>
        <Header />
        <div className="thoughts-landing animated fadeIn custom-container mt-5 mt-md-0 mb-5">
          <VerText classNames="left" text="Thoughts" />
          <Container>
            <Row className="post-container">{postsComponents}</Row>
          </Container>
          <LoadMoreBtn
            hide={this.state.postsLeftToShow.length > 0 ? true : false}
            onClick={this.loadMoreClicked}
          />
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Thoughts;
