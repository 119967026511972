import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

// Slick Sliderreact-slick
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";

//Components
import Card from "../Components/Card/Card";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";

// Data
import data from "../Data/Work";

// Images

class SingleWork extends Component {
  state = {
    post: []
  };

  componentDidMount() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  suggestedArticleClicked = () => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  };

  render() {
    const activePostUrl = window.location.pathname
      .split("/")
      [window.location.pathname.split("/").length - 1].toLowerCase();

    const activePost = data.work.filter(item => {
      if (
        item.link.toLowerCase().replace(/\s/g, "") ==
        activePostUrl.replace(/\s/g, "")
      ) {
        return item;
      }
    });

    // Generating suggested Articles
    const suggestedArticles = [];

    data.work.filter(item => {
      if (
        item.title.toLowerCase().replace(/\s/g, "") ==
        activePost[0].suggested.toLowerCase().replace(/\s/g, "")
      ) {
        suggestedArticles.push(item);
      }
    });

    // Iframe styles
    const iframe = {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%"
    };

    const iframeContainerStyle = {
      padding: "56.25% 0 0 0",
      position: "relative"
    };

    const sliderItems = activePost[0].slider.map(item => {
      return <img src={require("../Assets/images/" + item)} />;
    });

    const awardsItems = activePost[0].awards.map(item => {

        return (
          <div className="text-center">
            <img src={require("../Assets/images/awards" + item[0])} />
            <span className="d-block">{item[1]}</span>
          </div>
      )
    });

    var settings = {
      dots: false,
      arrows: true,
      infinite: true,
      slidesToScroll: 1,
      focusOnSelect: true,
      centerMode: false,
      slidesToShow: 1,
      autoplay: true,
      pauseOnHover: true
    };

    console.log(activePost[0]);

    return (
      <React.Fragment>
        <Header />
        <div className="custom-container single-work fadeIn animated mt-5">
          <Container>
            <Row>
              <Col sm={{ size: 12 }} md={{ size: 12 }} lg={{ size: 7 }} >
                <h4 class="h4-styles mb-1 d-md-none">
                  {activePost[0].card_title}
                </h4>
                <h3 className="h3-styles d-md-none">
                  {activePost[0].card_desc}
                </h3>
                <h4 class="h4-styles mb-1 d-none d-md-block">
                  {activePost[0].title}
                </h4>
                <p class="p-styles mb-2 mb-sm-4 d-md-block d-none text-uppercase">
                  {activePost[0].subtitle}
                </p>
                <p
                  className="mt-4"
                  dangerouslySetInnerHTML={{ __html: activePost[0].long_desc }}
                />

                {/* <img className="mt-5" src={require("../Assets/images/thoughts/" + activePost[0].content.image)} alt={activePost[0].content.image} /> */}
              </Col>

              {activePost[0].awards !== '' ? (
              <Col className="" sm={{ size: 12 }} md={{ size: 12 }} lg={{ size: 5 }}>
                <div className="awards">
                  {awardsItems}
                </div>
              </Col>
              ) : null}

              <Col sm={{ size: 12 }} className="video-container mt-5 mb-5">
                {activePost[0].video_id !== "" ? (
                  <div style={iframeContainerStyle}>
                    <iframe
                      src={
                        "https://player.vimeo.com/video/" +
                        activePost[0].video_id +
                        "?title=0&byline=0&portrait=0"
                      }
                      style={iframe}
                      frameborder="0"
                      allow="autoplay; fullscreen"
                      allowfullscreen
                    />
                  </div>
                ) : null}
              </Col>

              {/* optional second video */}
             
                {activePost[0].video2_id !== "" ? (
                <Col sm={{ size: 12 }} className="video-container mt-5 mb-5">
                  <div style={iframeContainerStyle}>
                    <iframe
                      src={
                        "https://player.vimeo.com/video/" +
                        activePost[0].video2_id +
                        "?title=0&byline=0&portrait=0"
                      }
                      style={iframe}
                      frameborder="0"
                      allow="autoplay; fullscreen"
                      allowfullscreen
                    />
                  </div>
                </Col>
                ) : null}
              


              {activePost[0].slider.length > 0 ? (
                <Col sm={{ size: 12 }} className="img-container  mb-5 pb-3">
                  <Slider {...settings}>{sliderItems}</Slider>
                </Col>
              ) : null}
            </Row>
          </Container>
        </div>

        <div className="custom-container suggested-case-study single-thought py-5 fadeIn animated">
          <Container>
            <Row>
              <Col
                onClick={this.suggestedArticleClicked}
                className="post animated fadeIn col-lg-9"
              >
                <h5 className="h2-styles mb-4 pl-3 pl-md-0">Next Case</h5>
                <Link to={suggestedArticles[0].link}>
                  <div className="post-image-container">
                    <LazyLoadImage
                      effect="opacity"
                      className="img-fluid"
                      src={require("../Assets/images/" +
                        (suggestedArticles[0].featured
                          ? suggestedArticles[0].suggested_img
                          : suggestedArticles[0].thumbnail_img.desktop))}
                      alt={suggestedArticles[0].thumbnail_img}
                    />
                  </div>

                  <div className="post-content-container mt-3 mx-2">
                    <h4 className="post-type h4-styles">
                      {suggestedArticles[0].card_title}
                    </h4>
                    <h3 className="post-title h3-styles mt-2">
                      {suggestedArticles[0].card_desc}
                    </h3>
                    <button className="mt-4">VIEW CASE</button>
                  </div>
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default SingleWork;
