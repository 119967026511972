import React from "react";

// Slick Sliderreact-slick
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";

//Components
import Carousel from "../Components/Carousel/Carousel";
import VerText from "../Components/VerText/VerText";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";

// data
import data from "../Data/services.json";

class Services extends React.Component {
  state = {
    allServices: data.services
  };

  render() {
    return (
      <React.Fragment>
        <Header />
        <section className="services-section custom-container animated fadeIn mb-5">
          <VerText classNames="left" text="Services" />
          <section className="services-intro line">
            <div className="content">
              <h1 className="h1-styles animated-t2 fadeIn delay-1 ">
                We’re a global team of creatives with ridiculously diverse
                backgrounds, skillsets and life experiences, gathered to help
                you overcome your growth-limiting challenges with moonshot
                creative business ideas. Here’s what we do best.
              </h1>
            </div>
          </section>
          <Carousel services={this.state.allServices} showSlideDetails={true} />
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Services;
