import React from "react";
import "./VerText.css";

export default class VerText extends React.Component {
  render() {
    return (
      <div className={`verText ` + this.props.classNames}>
        <p className="h4-styles">{this.props.text}</p>
      </div>
    );
  }
}
