import React from "react";
import { Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";

//Components
import VerText from "../Components/VerText/VerText";
import Carousel from "../Components/Carousel/Carousel";
import Brands from "../Components/Brands/Brands";
import FeaturedWork from "../Components/FeaturedWork/FeaturedWork";
import Card from "../Components/Card/Card";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";

// Slick Sliderreact-slick
import "../../node_modules/slick-carousel/slick/slick.css";
import "../../node_modules/slick-carousel/slick/slick-theme.css";

// data
import data from "../Data/services.json";
import workData from "../Data/Work.json";
import Data from "../Data/Thoughts.json";

class Home extends React.Component {
  state = {
    allServices: data.services,
    allWork: workData.work
  };

  componentDidMount() {
    const mainElement = document.getElementById("root");
    mainElement.classList.add("homepage");

    // Scroll to Top on page load
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  render() {
    const suggestedArticles = [];

    Data.thoughts.articles.filter(post => {
      if (
        post.showOnHome.toLowerCase() == "true" &&
        suggestedArticles.length < 3
      ) {
        suggestedArticles.push(
          <Card
            customClassName="col-12 col-sm-6 col-md-4 mb-5"
            link={"thoughts/" + post.link}
            type={post.type}
            date={post.date}
            title={post.title}
            status={post.status}
            img={post.image}
          />
        );
      }
    });

    const featuredWork = [];

    workData.work.slice(0, 6).map(work => {
      featuredWork.push(
        <FeaturedWork
          title={work.card_title}
          description={work.short_desc}
          img={work.thumbnail_img}
          link={"/work/" + work.link}
        />
      );
    });

    var settings = {
      dots: true,
      arrows: false,
      infinite: true,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      pauseOnHover: true
    };

    return (
      <React.Fragment>
        <Header />
        <div className="animated fadeIn">
          <section className="intro line">
            <div className="content">
              <h1 className="h1-styles animated-t2 fadeIn delay-1">
                We’re a global creative agency based in <span class="text-nowrap">New York</span>, Amsterdam and Toronto helping brands overcome growth-limiting challenges with moonshot creativity in branding, advertising and public relations.
              </h1>
            </div>
          </section>
          <section className="custom-container">
            <VerText classNames="left" text="Work" />
            <Slider {...settings} className="featuredWorkSlider">{featuredWork}</Slider>
          </section>

          <section className="custom-container">
            <VerText classNames="right" text="Services" />
            <Carousel services={this.state.allServices} />
          </section>

          <section className="space container">
            <h2 className="h2-styles">
              Our name is our philosophy:
              <br />
              aim for a trillion or don’t even try.
            </h2>
          </section>

          <section className="custom-container">
            <VerText classNames="left" text="Brand Experience" />
            <Brands />
          </section>
          {/* <section className="suggested-thoughts custom-container">
            <VerText classNames="right" text="Thoughts" />
            <Container>
              <Row>{suggestedArticles}</Row>
              <Link className="see-more h4-styles" to="/thoughts">
                See More
              </Link>
            </Container>
          </section> */}
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Home;
