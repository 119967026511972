import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

// Components
import Card from "../Components/Card/Card";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";

// Getting data
import data from "../Data/Thoughts";

class SingleThought extends Component {
  state = {
    post: []
  };

  componentDidMount() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  cardClicked = () => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  };

  render() {
    const activePostUrl = window.location.pathname
      .split("/")
      [window.location.pathname.split("/").length - 1].toLowerCase();

    const activePost = data.thoughts.articles.filter(item => {
      if (item.link.toLowerCase() == activePostUrl) {
        return item;
      }
    });

    // Generating suggested Articles
    const suggestedArticles = [];

console.log( data.thoughts.articles);

    data.thoughts.articles.filter(post => {
      // console.log('title >>'+post.title.toLowerCase().replace(/\s/g, ""));
      // console.log('activePost >>'+ activePost[0].suggested.toLowerCase().replace(/\s/g, ""));
      console.log(post);
      if (
        post.title.toLowerCase().replace(/\s/g, "") ==
        activePost[0].suggested.toLowerCase().replace(/\s/g, "")
      ) {
        suggestedArticles.push(post);
      }
    });

    // Generating html
    var htmlForPost = "";

    if (activePost[0].content.html) {
      activePost[0].content.html.map(item => {
        if (item[0] === "h2") {
          htmlForPost =
            htmlForPost +
            "<" +
            item[0] +
            ' class="h1-styles mr-md-5 mb-md-4">' +
            item[1] +
            "</" +
            item[0] +
            ">";
        } else if (item[0] === "p") {
          htmlForPost =
            htmlForPost +
            "<" +
            item[0] +
            ' class="mb-4 p-styles">' +
            item[1] +
            "</" +
            item[0] +
            ">";
        } else {
          htmlForPost =
            htmlForPost + "<" + item[0] + ">" + item[1] + "</" + item[0] + ">";
        }
      });
    }

    return (
      <React.Fragment>
        <Header />
        <div className="custom-container single-thought fadeIn animated mt-5">
          <Container>
            <Row>
              <Col className="thought-container">
                <h4 className="postDate h4-styles mb-sm-4">
                  {activePost[0].type}
                  <span>
                    <i class="mr-2 ml-1" />
                    {activePost[0].date}
                  </span>
                </h4>
                <h1 className="h2-styles mb-2">{activePost[0].title}</h1>
                <p
                  className="post-publisher"
                  dangerouslySetInnerHTML={{ __html: activePost[0].publisher }}
                />
                <img
                  className="mt-5  mb-5"
                  src={require("../Assets/images/thoughts/" +
                    activePost[0].content.image)}
                  alt={activePost[0].content.image}
                />
                <div className="row postHeadings pb-md-3 pb-lg-5">
                  <div className="col-12 col-md-6 col-lg-5 mb-4 subTitle">
                    <h3 className="h3-styles">
                      {activePost[0].content.headings.title}
                    </h3>
                  </div>
                  <div className="col-12 col-md-6 col-lg-7 mb-4 subHeading">
                    <p
                      className="p-styles"
                      dangerouslySetInnerHTML={{
                        __html: activePost[0].content.headings.subTitle
                      }}
                    />
                  </div>
                </div>
                <div
                  className="post-content"
                  dangerouslySetInnerHTML={{ __html: htmlForPost }}
                />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="custom-container order-articles single-thought fadeIn animated py-5 mt-lg-4">
          <Container>
            <Row>
              <Col
                onClick={this.cardClicked}
                className="post animated fadeIn col-lg-9"
              >
                <h5 className="h2-styles mb-4 pl-3 pl-md-0">Next Article</h5>
                <Link to={suggestedArticles[0].link}>
                  <div className="post-image-container">
                    <LazyLoadImage
                      effect="opacity"
                      className="img-fluid"
                      src={require("../Assets/images/" +
                        suggestedArticles[0].image)}
                      alt={suggestedArticles[0].thumbnail_img}
                    />
                  </div>

                  <div className="post-content-container mt-3 mx-2">
                    <h4 className="post-type h4-styles">
                      {suggestedArticles[0].type}
                    </h4>
                    <h3 className="post-title h3-styles mt-2">
                      {suggestedArticles[0].title}
                    </h3>
                    <button className="mt-4">VIEW ARTICLE</button>
                  </div>
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default SingleThought;
