import React from "react";
import { Container, Row } from "reactstrap";

//Components
import VerText from "../Components/VerText/VerText";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

// Images
// import Hero from "../Assets/images/about/about-hero.png";
import Hero from "../Assets/images/about/about-hero.jpg";
import HeroMobile from "../Assets/images/about/about-hero.jpg";
import Sarah from "../Assets/images/about/sarah.png";
import Alex from "../Assets/images/about/Alex.jpg";
import Aubrey from "../Assets/images/about/Aubrey.jpg";
import Chantelle from "../Assets/images/about/Chantelle.jpg";

class About extends React.Component {
  state = {};

  render() {
    const TeamCard = props => {
      return (
        <div className="col-12 mb-5 col-md-6 col-xl-3 text-center text-md-left team-card">
          <LazyLoadImage effect="opacity" src={props.img} alt={props.name} />
          <div className="content">
            <h3 className="h3-styles">{props.name}</h3>
            <h4
              className="h4-styles"
              dangerouslySetInnerHTML={{ __html: props.title }}
            />
          </div>
        </div>
      );
    };

    return (
      <React.Fragment>
        <Header />
        <section className="about-section custom-container animated fadeIn">
          <VerText classNames="left" text="About" />
          <Container>
            <Row className="about-content">
              <div className="hero-img col-12 col-lg-7">
                <picture>
                  <source media="(min-width: 768px)" srcset={Hero} />
                  <img src={HeroMobile} alt="Hero Image" />
                </picture>
              </div>
              <div className="copy col-12 col-lg-5">
                <div>
                  <p className="p-styles">
                    Zerotrillion was born out of our contempt for the rampant
                    acceptance of mediocrity.
                  </p>
                  <p className="p-styles">
                    To be a real difference maker for businesses, you have to
                    create ideas that give your audience something to contend
                    with.
                  </p>
                  <p className="p-styles">
                    We’re not here to help you compromise. We’re the creative
                    partner to help you realize the most ambitious version of
                    your brand.
                  </p>
                  <p className="p-styles">
                    We’re here to give you everything, because anything less is
                    giving you nothing.
                  </p>
                  <p className="p-styles">
                    Meaningful growth isn’t a comfortable cruise around the
                    block — it’s a bumpy slog to the stars.
                  </p>
                </div>
              </div>
            </Row>
          </Container>
          <div className="team mb-5">
            <h3 className="h3-styles">Global Leadership Team</h3>
            <Container>
              <Row>
                <TeamCard
                  name="Sarah Nguyen"
                  title="<span>Creative Director</span>"
                  img={Sarah}
                />
                <TeamCard
                  name="Alex Paquin"
                  title="Chief Executive Officer"
                  img={Alex}
                />
                <TeamCard
                  name="Aubrey Podolsky"
                  title="Managing Director, Toronto <span class='d-block'>Global CSO</span>"
                  img={Aubrey}
                />
                <TeamCard
                  name="Chantelle Brinkley"
                  title="Managing Director, <span>New York</span>"
                  img={Chantelle}
                />
              </Row>
            </Container>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

export default About;
