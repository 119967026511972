import React from "react";
import Slider from "react-slick";
import data from "../../Data/brands.json";

// Slick Sliderreact-slick
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import "./Brands.css";

export default class Brands extends React.Component {
  render() {
    const sliderItems = data.brands.map(brand => {
      return (
        <div className="single-brand">
          <img
            src={require("../../Assets/images/brands/" + brand.image)}
            alt={brand.alt}
          />
        </div>
      );
    });

    // Mobile to show 4 logos
    const chunked_arr = [];
    let index = 0;

    while (index < data.brands.length) {
      chunked_arr.push(data.brands.slice(index, 4 + index));
      index += 4;
    }

    const sliderItemsMobile = chunked_arr.map(item => {
      return (
        <div className="row mobileBrand">
          {item.map(brand => {
            return (
              <div className="col-6 brand">
                <img
                  src={require("../../Assets/images/brands/" + brand.image)}
                />
              </div>
            );
          })}
        </div>
      );
    });

    var settings = {
      dots: false,
      arrows: false,
      slidesToScroll: 0,
      slidesToShow: 10
    };

    var customSettings = {
      dots: true,
      arrows: false,
      slidesToScroll: 1,
      slidesToShow: 1,
      centerMode: false,
      infinite: true,
      slidesToScroll: 1
    };

    return (
      <React.Fragment>
        <Slider
          className="brands-slider container d-none d-md-block"
          {...settings}
        >
          {sliderItems}
        </Slider>

        <Slider
          className="brands-slider container d-block d-md-none"
          {...customSettings}
        >
          {sliderItemsMobile}
        </Slider>
      </React.Fragment>
    );
  }
}
